<ng-template #headingTemplate>
  <h1>{{ 'VIEW.MAIN.COACHES.COACHES_DETAILS' | translate }}</h1>

  <div class="space-x-4">
    <button type="button" class="btn btn-outline-primary" (click)="onBackClick()">
      <svg-icon src="asset/image/icon/chevron-left.svg"></svg-icon>
      <span>{{ 'COMMON.BACK' | translate }}</span>
    </button>

    <button type="button" class="btn btn-primary" (click)="onEditClick()">
      {{ 'COMMON.EDIT' | translate }}
    </button>
  </div>
</ng-template>

<section class="section">
  <ng-container *ngIf="page$ | async as page">
    <div class="row p-0 m-0 no-gutters">
      <div class="col-2 p-0 d-none d-lg-block">
        <app-avatar [src]="page.user.avatar?.defaultAsset?.url" size="100%"></app-avatar>
      </div>

      <div class="col-12 p-0 d-lg-none d-block text-center mt-4 mb-4">
        <app-avatar [src]="page.user.avatar?.defaultAsset?.url"
                    size="100%"
                    [style.--max-width]="'400px'">
        </app-avatar>
      </div>

      <div class="pl-lg-6 col-12 col-lg-7 p-0">
        <div class="row no-gutters m-0 p-0 mb-4">
          <div class="col-12 col-lg-6 d-flex flex-column gap-0 d-block d-lg-none mb-4 mb-lg-0">
            <p class="details-label">{{ 'COMMON.DATE_CREATED' | translate }}:</p>
            <p>{{ page.user.createdDate | date: 'dd/MM/yyyy' }}</p>
          </div>

          <div class="col-12 col-sm-6 d-flex gap-0 flex-column">
            <p class="details-label">{{ "VIEW.MAIN.COACHES.FIRST_NAME" | translate }}</p>
            <p>{{ page.user.firstName }}</p>
          </div>

          <div class="col-12 col-sm-6 d-flex gap-0 flex-column">
            <p class="details-label">{{ "VIEW.MAIN.COACHES.LAST_NAME" | translate }}</p>
            <p>{{ page.user.lastName }}</p>
          </div>
        </div>

        <div class="row no-gutters m-0 p-0 mb-4">
          <div class="col-12 col-sm-6 d-flex gap-0 flex-column">
            <p class="details-label">{{ "VIEW.MAIN.COACHES.EMAIL" | translate }}</p>
            <p>{{ page.user.email }}</p>
          </div>

          <div class="col-12 col-sm-6 d-flex gap-0 flex-column">
            <p class="details-label">{{ "VIEW.MAIN.COACHES.ZOOM_USER_EMAIL" | translate }}</p>
            <p>{{ page.zoomUserEmail | replaceEmpty }}</p>
          </div>

          <div class="col-12 col-sm-6 d-flex gap-0 flex-column">
            <p class="details-label">{{ "VIEW.MAIN.COACHES.PHONE" | translate }}</p>
            <p>{{ page.user.phone | replaceEmpty }}</p>
          </div>
        </div>

        <div class="row no-gutters m-0 p-0 mb-4">
          <div class="col-12 col-sm-6 d-flex gap-0 flex-column">
            <p class="details-label">{{ "VIEW.MAIN.COACHES.LOCATION" | translate }}</p>
            <p>{{ page.location }}</p>
          </div>
          <div class="col-12 col-sm-6 d-flex gap-0 flex-column">
            <p class="details-label">{{ "VIEW.MAIN.COACHES.STATUS" | translate }}</p>
            <p>{{ (page.user.enabled ? 'ENUM.STATUS.ACTIVE' : 'ENUM.STATUS.INACTIVE') | translate }}</p>
          </div>
        </div>
        <div class="row no-gutters m-0 p-0 mb-4">
          <div class="col-12 col-sm-6 d-flex gap-0 flex-column">
            <!-- TODO 2ns phasse -->
            <!-- <p class="details-label">{{"VIEW.MAIN.COACHES.AVERAGE_LIVE_CLASS_RATING" | translate}}</p> -->
            <!-- <app-rating [rating]="page.liveClassRating"></app-rating> -->
          </div>
          <div class="col-12 col-sm-6 d-flex gap-0 flex-column">
            <!-- TODO 2ns phasse -->
            <!-- <p class="details-label">{{"VIEW.MAIN.COACHES.AVERAGE_OD_VIDEO_RATING" | translate}}</p> -->
            <!-- <app-rating [rating]="page.onVideoRating"></app-rating> -->
          </div>
        </div>

        <div class="row no-gutters m-0 p-0 mb-4">
          <div class="col-12 col-sm-6 d-flex gap-0 flex-column">
            <p class="details-label">{{ "VIEW.MAIN.COACHES.TYPE_OF_COACH" | translate }}</p>
            <p>{{ "ENUM.TYPE." + page.type | translate }}</p>
          </div>
        </div>

        <div *ngIf="page.bio" class="d-flex flex-column mb-4">
          <p class="details-label">{{ "VIEW.MAIN.COACHES.BIO" | translate }}</p>
          <p>{{ page.bio }}</p>
        </div>

        <div *ngIf="page.coachHobbies.length > 0" class="d-flex flex-column mb-4">
          <p class="details-label">{{ "VIEW.MAIN.COACHES.HOBBIES_PASSIONS" | translate }}</p>
          <ul>
            <li *ngFor="let hobbiesAndPassion of page.coachHobbies">{{ hobbiesAndPassion.hobby }}</li>
          </ul>
        </div>

        <div *ngIf="page.coachEducations.length > 0" class="d-flex flex-column mb-4">
          <p class="details-label">{{ "VIEW.MAIN.COACHES.EDUCATION" | translate }}</p>
          <ul>
            <li *ngFor="let education of page.coachEducations">{{ education.education }}</li>
          </ul>
        </div>

        <div *ngIf="page.coachCertifications.length > 0" class="d-flex flex-column mb-4">
          <p class="details-label">{{ "VIEW.MAIN.COACHES.CERTIFICATIONS" | translate }}</p>
          <ul>
            <li *ngFor="let certification of page.coachCertifications">{{ certification.certification }}</li>
          </ul>
        </div>
      </div>
      <div class="col-3 p-0 d-none d-lg-block">
        <p class="details-label">
          {{ 'COMMON.DATE_CREATED' | translate }}: {{ page.user.createdDate | date: 'dd/MM/yyyy' }}
        </p>
      </div>
    </div>
  </ng-container>
</section>
