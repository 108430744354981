<ng-template #headingTemplate>
  <h1>{{ 'COMPONENT.ORGANIZATIONS.EDIT' | translate }}</h1>

  <div class="d-flex flex-wrap gap-4">
    <button type="button" class="btn btn-outline-primary" (click)="onBackClick()">
      <svg-icon src="asset/image/icon/chevron-left.svg"></svg-icon>
      <span>{{ 'COMMON.BACK' | translate }}</span>
    </button>

    <button [disabled]="uploadStarted" type="button" class="btn btn-primary" (click)="onSaveClick()">
      <span *ngIf="uploadStarted" class="loader-white"></span>
      <span>{{ 'COMMON.SAVE' | translate }}</span>
    </button>
  </div>
</ng-template>

<section class="section">
  <form *ngIf="organization" #form="ngForm" novalidate (keyup.enter)="onSaveClick()">
    <div class="row">
      <div class="col-12 col-lg-10">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="form-group">
              <label for="name">{{ 'COMPONENT.ORGANIZATIONS.NAME' | translate }}</label>
              <input
                type="text"
                id="name"
                name="name"
                #name="ngModel"
                class="form-control"
                placeholder="{{ 'COMPONENT.ORGANIZATIONS.NAME' | translate }}"
                [ngClass]="{'is-invalid': form.submitted && name.invalid, 'is-valid': form.submitted && name.valid }"
                [(ngModel)]="organization.name"
                required
                [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"
              />

              <div class="invalid-feedback" *ngIf="form.submitted && name.invalid">
                <div *ngIf="name.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
                <div *ngIf="name.errors['maxLength']">
                  {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_150} }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="form-group">
              <label for="contactName">{{ 'COMPONENT.ORGANIZATIONS.CONTACT_NAME' | translate }}</label>
              <input
                type="text"
                id="contactName"
                name="contactName"
                #contactName="ngModel"
                class="form-control"
                placeholder="{{ 'COMPONENT.ORGANIZATIONS.CONTACT_NAME' | translate }}"
                [ngClass]="{'is-invalid': form.submitted && contactName.invalid, 'is-valid': form.submitted && contactName.valid }"
                [(ngModel)]="organization.contactName"
                [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"
              />
              <div class="text-sm-light color-orange ml-4 mt-1">* {{ 'COMMON.OPTIONAL' | translate }}</div>
              <div class="invalid-feedback" *ngIf="form.submitted && contactName.invalid">
                <div *ngIf="contactName.errors['maxLength']">
                  {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_150} }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="form-group">
              <label for="phone">{{ 'COMPONENT.ORGANIZATIONS.PHONE' | translate }}</label>
              <input
                type="text"
                id="phone"
                name="phone"
                #phone="ngModel"
                class="form-control"
                placeholder="{{ 'COMPONENT.ORGANIZATIONS.PHONE' | translate }}"
                [ngClass]="{'is-invalid': form.submitted && phone.invalid, 'is-valid': form.submitted && phone.valid }"
                [(ngModel)]="organization.phone"
                [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"
              />
              <div class="text-sm-light color-orange ml-4 mt-1">* {{ 'COMMON.OPTIONAL' | translate }}</div>
              <div class="invalid-feedback" *ngIf="form.submitted && phone.invalid">
                <div *ngIf="phone.errors['maxLength']">
                  {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_150} }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="form-group">
              <label for="type">{{ "COMPONENT.ORGANIZATIONS.TYPE" | translate }}</label>
              <ng-select
                type="text"
                id="type"
                name="type"
                #type="ngModel"
                placeholder="{{ 'COMPONENT.ORGANIZATIONS.TYPE' | translate }}"
                class="type-select"
                [ngClass]="{'is-invalid': form.submitted && type.invalid, 'is-valid': form.submitted && type.valid }"
                [(ngModel)]="organization.type"
                required>
                <ng-option *ngFor="let option of organizationTypeOptions" [value]="option.value">
                  {{ option.label | translate }}
                </ng-option>
              </ng-select>
              <div class="invalid-feedback" *ngIf="form.submitted && type.invalid">
                <div *ngIf="type.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="form-group">
              <label for="tier">{{ "DOMAIN.ORGANIZATION.DATA.SUBSCRIPTION_TIER.LABEL" | translate }}</label>
              <ng-select
                class="type-select"
                type="text"
                id="tier"
                name="tier"
                #tier="ngModel"
                bindLabel="label"
                [placeholder]="'DOMAIN.ORGANIZATION.DATA.SUBSCRIPTION_TIER.LABEL' | translate"
                [ngClass]="{'is-invalid': form.submitted && tier.invalid, 'is-valid': form.submitted && tier.valid }"
                [(ngModel)]="organization.subscriptionTier"
                required>
                <ng-option *ngFor="let option of subscriptionTierOptions"
                           [value]="option.value">{{ option.label | translate }}
                </ng-option>
              </ng-select>

              <div class="invalid-feedback" *ngIf="form.submitted && tier.invalid">
                <div *ngIf="tier.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-6">
            <div class="form-group">
              <label for="apiurl">{{ "COMPONENT.ORGANIZATIONS.APIURL" | translate }}</label>
              <input
                type="text"
                id="apiurl"
                name="apiurl"
                #apiurl="ngModel"
                class="form-control"
                placeholder="{{'COMPONENT.ORGANIZATIONS.APIURL' | translate }}"
                [ngClass]="{'is-invalid': form.submitted && apiurl.invalid, 'is-valid': form.submitted && apiurl.valid }"
                [(ngModel)]="organization.apiUrl"
              />
              <div class="text-sm-light color-orange ml-4 mt-1">* {{ 'COMMON.OPTIONAL' | translate }}</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="form-group">
              <label for="street">{{ "COMPONENT.ORGANIZATIONS.STREET" | translate }}</label>
              <input
                type="text"
                id="street"
                name="street"
                #street="ngModel"
                class="form-control"
                placeholder="{{'COMPONENT.ORGANIZATIONS.STREET' | translate }}"
                [ngClass]="{'is-invalid': form.submitted && street.invalid, 'is-valid': form.submitted && street.valid }"
                [(ngModel)]="organization.address.street"
                [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"
              />
              <div class="invalid-feedback" *ngIf="form.submitted && street.invalid">
                <div *ngIf="street.errors['maxLength']">
                  {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_150} }}
                </div>
              </div>
              <div class="text-sm-light color-orange ml-4 mt-1">* {{ 'COMMON.OPTIONAL' | translate }}</div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="form-group">
              <label for="city">{{ "COMPONENT.ORGANIZATIONS.CITY" | translate }}</label>
              <input
                type="text"
                id="city"
                name="city"
                #city="ngModel"
                class="form-control"
                placeholder="{{'COMPONENT.ORGANIZATIONS.CITY' | translate }}"
                [ngClass]="{'is-invalid': form.submitted && city.invalid, 'is-valid': form.submitted && city.valid }"
                [(ngModel)]="organization.address.city"
                [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"
              />
              <div class="text-sm-light color-orange ml-4 mt-1">* {{ 'COMMON.OPTIONAL' | translate }}</div>
              <div class="invalid-feedback" *ngIf="form.submitted && city.invalid">
                <div *ngIf="city.errors['maxLength']">
                  {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_150} }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="form-group">
              <label for="state">{{ "COMPONENT.ORGANIZATIONS.STATE" | translate }}</label>
              <input
                type="text"
                id="state"
                name="state"
                #state="ngModel"
                class="form-control"
                placeholder="{{'COMPONENT.ORGANIZATIONS.STATE' | translate }}"
                [ngClass]="{'is-invalid': form.submitted && state.invalid, 'is-valid': form.submitted && state.valid }"
                [(ngModel)]="organization.address.state"
                [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"
              />
              <div class="text-sm-light color-orange ml-4 mt-1">* {{ 'COMMON.OPTIONAL' | translate }}</div>
              <div class="invalid-feedback" *ngIf="form.submitted && state.invalid">
                <div *ngIf="state.errors['maxLength']">
                  {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_150} }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="form-group">
              <label for="zipCode">{{ "COMPONENT.ORGANIZATIONS.ZIP_CODE" | translate }}</label>
              <input
                type="text"
                id="zipCode"
                name="zipCode"
                #zipCode="ngModel"
                class="form-control"
                placeholder="{{ 'COMPONENT.ORGANIZATIONS.ZIP_CODE' | translate }}"
                [ngClass]="{'is-invalid': form.submitted && zipCode.invalid, 'is-valid': form.submitted && zipCode.valid }"
                [(ngModel)]="organization.address.zipCode"
                [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"
              />
              <div class="text-sm-light color-orange ml-4 mt-1">* {{ 'COMMON.OPTIONAL' | translate }}</div>
              <div class="invalid-feedback" *ngIf="form.submitted && zipCode.invalid">
                <div *ngIf="zipCode.errors['maxLength']">
                  {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_150} }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row"></div>
        <div class="row">
          <div class="col-12 col-lg-9 mb-4">
            <app-thumbnail-and-files-upload
              [thumbnailPlaceholder]="'COMMON.LOGO' | translate"
              [thumbnailUrl]="defaultAssetUrl"
              [thumbnailMode]="true"
              (startUpload)="handleStartUpload($event)"
              (imageId)="handleImageId($event)"
            ></app-thumbnail-and-files-upload>
          </div>
        </div>
      </div>
    </div>
  </form>
</section>
