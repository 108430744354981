import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import { State } from 'app/common/State';
import { OrganizationAddressDTO } from 'app/data/dto/organization/OrganizationAddressDTO';
import { OrganizationCreateRequestDTO } from 'app/data/dto/organization/OrganizationCreateRequestDTO';
import { OrganizationDetailsResponseDTO } from 'app/data/dto/organization/OrganizationDetailsResponseDTO';
import { OrganizationType } from 'app/data/enum/organizations/OrganizationType';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { OrganizationModel } from 'app/model/OrganizationModel';
import { ViewUtil } from 'app/util/ViewUtil';
import { of, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { PortalUtil } from 'app/util/PortalUtil';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';
import { SubscriptionTier } from 'app/data/enum/SubscriptionTier';

@Component({
  selector: 'app-organizations-create',
  templateUrl: './OrganizationsCreateComponent.html',
  styleUrls: [ './OrganizationsCreateComponent.scss' ]
})
export class OrganizationsCreateComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  private destroy$: Subject<void> = new Subject<void>();
  public uploadStarted: boolean = false;
  public organization: OrganizationCreateRequestDTO;
  public subscriptionTierOptions: OptionItem<string>[] = this.viewUtil.createEnumSelectOptions(SubscriptionTier, 'SUBSCRIPTION_TIER');
  public organizationTypeOptions: OptionItem<OrganizationType>[] = this.viewUtil.createEnumSelectOptions(OrganizationType, 'ORGANIZATION_TYPE');

  @ViewChild('form') private form: NgForm;

  constructor(private stateService: StateService,
              private organizationModel: OrganizationModel,
              private translate: TranslateService,
              public viewUtil: ViewUtil,
              public applicationModel: ApplicationModel,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef) {
    this.organization = new OrganizationCreateRequestDTO();
    this.organization.address = new OrganizationAddressDTO();
  }

  public ngOnInit(): void {
    this.applicationModel.selectSideBarItemWithState(State.MAIN.ADMIN.ORGANIZATIONS.LIST);

    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public handleImageId(imageId: number): void {
    this.organization.imageId = imageId;
  }

  public handleStartUpload(uploadStart: boolean): void {
    this.uploadStarted = uploadStart;
  }

  public onBackClick(): void {
    this.stateService.go(State.MAIN.ADMIN.ORGANIZATIONS.LIST);
  }

  public onSaveClick(): void {
    this.form.onSubmit(null);
    if (this.form.valid) {
      this.organizationModel
        .createOrganization(this.organization)
        .pipe(
          takeUntil(this.destroy$),
          tap(({ id }: OrganizationDetailsResponseDTO) => {
            this.stateService.go(State.MAIN.ADMIN.ORGANIZATIONS.DETAILS, { id });
          }),
          catchError((err) => {
            this.viewUtil.handleServerError(err);
            this.uploadStarted = false;
            return of(null);
          })
        )
        .subscribe();
    }
  }
}
