<ng-template #headingTemplate>
  <h1>{{ 'VIEW.MAIN.USER.EDIT_USER' | translate }}</h1>

  <div class="space-x-4">
    <button type="button" class="btn btn-outline-primary" (click)="onBackClick()">
      <svg-icon src="asset/image/icon/chevron-left.svg"></svg-icon>
      <span>{{ 'COMMON.BACK' | translate }}</span>
    </button>

    <button [disabled]="uploadStarted" type="button" class="btn btn-primary" (click)="updateSenior()">
      <span *ngIf="uploadStarted" class="loader-white position-absolute"></span>
      <span>{{ 'COMMON.SAVE' | translate }}</span>
    </button>
  </div>
</ng-template>

<section class="section">
  <form *ngIf="senior" #userForm="ngForm" novalidate (keyup.enter)="updateSenior()">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
        <div class="form-group">
          <label for="firstName">{{ 'VIEW.MAIN.USER.FIRST_NAME' | translate }}</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            #firstName="ngModel"
            class="form-control"
            placeholder="{{ 'VIEW.MAIN.USER.FIRST_NAME' | translate }}"
            [ngClass]="{'is-invalid': userForm.submitted && firstName.invalid, 'is-valid': userForm.submitted && firstName.valid }"
            [(ngModel)]="senior.user.firstName"
            [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"
            required/>

          <div class="invalid-feedback" *ngIf="userForm.submitted && firstName.invalid">
            <div *ngIf="firstName.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
            <div *ngIf="firstName.errors['maxLength']">
              {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_150} }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
        <div class="form-group">
          <label for="lastName">{{ 'VIEW.MAIN.USER.LAST_NAME' | translate }}</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            #lastName="ngModel"
            class="form-control"
            placeholder="{{ 'VIEW.MAIN.USER.LAST_NAME' | translate }}"
            [ngClass]="{'is-invalid': userForm.submitted && lastName.invalid, 'is-valid': userForm.submitted && lastName.valid }"
            [(ngModel)]="senior.user.lastName"
            [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"
            required/>

          <div class="invalid-feedback" *ngIf="userForm.submitted && lastName.invalid">
            <div *ngIf="lastName.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
            <div *ngIf="lastName.errors['maxLength']">
              {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_150} }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
        <div class="form-group">
          <label for="nickName">{{ "VIEW.MAIN.USER.NICKNAME" | translate }}</label>
          <input
            type="text"
            id="nickName"
            name="nickName"
            #nickName="ngModel"
            class="form-control"
            placeholder="{{ 'VIEW.MAIN.USER.NICKNAME' | translate }}"
            [ngClass]="{'is-invalid': userForm.submitted && nickName.invalid, 'is-valid': userForm.submitted && nickName.valid }"
            [(ngModel)]="senior.user.nickname"
            [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"/>
          <div class="text-sm-light color-orange ml-4 mt-1">* {{ 'COMMON.OPTIONAL' | translate }}</div>

          <div class="invalid-feedback" *ngIf="userForm.submitted && nickName.invalid">
            <div *ngIf="nickName.errors['maxLength']">
              {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_150} }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
        <div class="form-group">
          <label for="gender">{{ "VIEW.MAIN.USER.GENDER" | translate }}</label>
          <ng-select
            type="text"
            id="gender"
            name="gender"
            #gender="ngModel"
            placeholder="{{'VIEW.MAIN.USER.GENDER' | translate }}"
            [ngClass]="{'is-invalid': userForm.submitted && nickName.invalid, 'is-valid': userForm.submitted && nickName.valid }"
            [(ngModel)]="senior.gender"
            [appMaxLength]="Constant.TEXT_MAX_LENGTH_150">
            <ng-option *ngFor="let option of genderOptions"
                       [value]="option.value">{{ option.label | translate }}
            </ng-option>
          </ng-select>
          <div class="text-sm-light color-orange ml-4 mt-1">* {{ 'COMMON.OPTIONAL' | translate }}</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
        <div class="form-group">
          <label for="email">{{ "VIEW.MAIN.USER.EMAIL" | translate }}</label>
          <input
            type="text"
            id="email"
            name="email"
            #email="ngModel"
            class="form-control"
            placeholder="{{'VIEW.MAIN.USER.EMAIL' | translate }}"
            [ngClass]="{'is-invalid': userForm.submitted && email.invalid, 'is-valid': userForm.submitted && email.valid }"
            [(ngModel)]="senior.user.email"
            [appMaxLength]="Constant.TEXT_MAX_LENGTH_150" ngvemail
            [required]="senior.user.registrationType === RegistrationType.EMAIL"/>
          <div class="text-sm-light color-orange ml-4 mt-1"
               *ngIf="senior.user.registrationType !== RegistrationType.EMAIL">* {{ 'COMMON.OPTIONAL' | translate }}
          </div>

          <div class="invalid-feedback" *ngIf="userForm.submitted && email.invalid">
            <div *ngIf="email.errors['email']">{{ 'VALIDATION.EMAIL' | translate }}</div>
            <div *ngIf="email.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
            <div *ngIf="email.errors['maxLength']">
              {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_150} }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
        <div class="form-group">
          <label for="phone">{{ "VIEW.MAIN.USER.PHONE" | translate }}</label>
          <input
            type="text"
            id="phone"
            name="phone"
            #phone="ngModel"
            class="form-control"
            placeholder="{{'VIEW.MAIN.USER.PHONE' | translate }}"
            [ngClass]="{'is-invalid': userForm.submitted && phone.invalid, 'is-valid': userForm.submitted && phone.valid }"
            [showMaskTyped]="true"
            [(ngModel)]="senior.user.phone"
            [prefix]="Constant.PHONE_NUMBER_RULES.prefix + ' '"
            [shownMaskExpression]="Constant.PHONE_NUMBER_RULES.expression"
            [pattern]="Constant.PHONE_NUMBER_RULES.pattern"
            [mask]="Constant.PHONE_NUMBER_RULES.mask"
            [required]="senior.user.registrationType === RegistrationType.PHONE"/>
          <div class="text-sm-light color-orange ml-4 mt-1"
               *ngIf="senior.user.registrationType !== RegistrationType.PHONE">* {{ 'COMMON.OPTIONAL' | translate }}
          </div>

          <div class="invalid-feedback" *ngIf="userForm.submitted && phone.invalid">
            <div *ngIf="phone.errors.required">{{ 'VALIDATION.REQUIRED' | translate }}</div>
            <div *ngIf="phone.errors.pattern">{{ 'VALIDATION.PHONE' | translate }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
        <div class="form-group">
          <label for="dateOfBirth">{{ "VIEW.MAIN.USER.DATE_OF_BIRTH" | translate }}</label>
          <input
            type="text"
            id="dateOfBirth"
            name="dateOfBirth"
            #dateOfBirth="ngModel"
            class="form-control date-icon"
            placeholder="{{'VIEW.MAIN.USER.DATE_OF_BIRTH' | translate }}"
            [ngClass]="{'is-invalid': userForm.submitted && dateOfBirth.invalid, 'is-valid': userForm.submitted && dateOfBirth.valid }"
            [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', containerClass: 'theme-custom', maxDate: maxDateOfBirth}"
            [(ngModel)]="senior.dateOfBirth"
            [appMaxDate]="maxDateOfBirth"
            bsDatepicker required/>

          <div class="invalid-feedback" *ngIf="userForm.submitted && dateOfBirth.invalid">
            <div *ngIf="dateOfBirth.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
        <div class="form-group">
          <label for="status">{{ "VIEW.MAIN.USER.STATUS" | translate }}</label>
          <ng-select
            id="status"
            name="status"
            #status="ngModel"
            [placeholder]="'VIEW.MAIN.USER.STATUS' | translate"
            [searchable]="false"
            [ngClass]="{'is-invalid': userForm.submitted && status.invalid, 'is-valid': userForm.submitted && status.valid }"
            [(ngModel)]="senior.user.enabled"
            required>
            <ng-option *ngFor="let status of statusOptions"
                       [value]="status.value">{{ status.label | translate }}
            </ng-option>
          </ng-select>

          <div class="invalid-feedback" *ngIf="userForm.submitted && status.invalid">
            <div *ngIf="status.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
        <div class="form-group">
          <label for="street">{{ "VIEW.MAIN.USER.STREET" | translate }}</label>
          <input
            type="text"
            id="street"
            name="street"
            #street="ngModel"
            class="form-control"
            placeholder="{{'VIEW.MAIN.USER.STREET' | translate }}"
            [ngClass]="{'is-invalid': userForm.submitted && street.invalid, 'is-valid': userForm.submitted && street.valid }"
            [(ngModel)]="senior?.address.street"
            [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"/>
          <div class="text-sm-light color-orange ml-4 mt-1">* {{ 'COMMON.OPTIONAL' | translate }}</div>

          <div class="invalid-feedback" *ngIf="userForm.submitted && street.invalid">
            <div *ngIf="street.errors['maxLength']">
              {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_150} }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
        <div class="form-group">
          <label for="city">{{ "VIEW.MAIN.USER.CITY" | translate }}</label>
          <input
            type="text"
            id="city"
            name="city"
            #city="ngModel"
            class="form-control"
            placeholder="{{ 'VIEW.MAIN.USER.LAST_NAME' | translate }}"
            [ngClass]="{'is-invalid': userForm.submitted && city.invalid, 'is-valid': userForm.submitted && city.valid }"
            [(ngModel)]="senior.address.city"
            [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"/>
          <div class="text-sm-light color-orange ml-4 mt-1">* {{ 'COMMON.OPTIONAL' | translate }}</div>

          <div class="invalid-feedback" *ngIf="userForm.submitted && city.invalid">
            <div *ngIf="city.errors['maxLength']">
              {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_150} }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
        <div class="form-group">
          <label for="state">{{ "VIEW.MAIN.USER.STATE" | translate }}</label>
          <input
            type="text"
            id="state"
            name="state"
            #state="ngModel"
            class="form-control"
            placeholder="{{'VIEW.MAIN.USER.STATE' | translate }}"
            [ngClass]="{'is-invalid': userForm.submitted && state.invalid, 'is-valid': userForm.submitted && state.valid }"
            [(ngModel)]="senior.address.state"
            [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"/>
          <div class="text-sm-light color-orange ml-4 mt-1">* {{ 'COMMON.OPTIONAL' | translate }}</div>

          <div class="invalid-feedback" *ngIf="userForm.submitted && state.invalid">
            <div *ngIf="state.errors['maxLength']">
              {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_150} }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
        <div class="form-group">
          <label for="zipCode">{{ "VIEW.MAIN.USER.ZIP_CODE" | translate }}</label>
          <input
            type="text"
            id="zipCode"
            name="zipCode"
            #zipCode="ngModel"
            class="form-control"
            placeholder="{{'VIEW.MAIN.USER.ZIP_CODE' | translate }}"
            [ngClass]="{'is-invalid': userForm.submitted && zipCode.invalid, 'is-valid': userForm.submitted && zipCode.valid }"
            [(ngModel)]="senior.address.zipCode"
            [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"/>
          <div class="text-sm-light color-orange ml-4 mt-1">* {{ 'COMMON.OPTIONAL' | translate }}</div>

          <div class="invalid-feedback" *ngIf="userForm.submitted && zipCode.invalid">
            <div *ngIf="zipCode.errors['maxLength']">
              {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_150} }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    Subscription Type-->
    <div class="row">
      <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
        <div class="form-group">
          <label for="subscriptionType">{{ 'DOMAIN.USER.DATA.SUBSCRIPTION.TYPE.LABEL' | translate }}</label>
          <ng-select
            type="text"
            id="subscriptionType"
            name="subscriptionType"
            #subscriptionType="ngModel"
            placeholder="{{ 'DOMAIN.USER.DATA.SUBSCRIPTION.TYPE.PLACEHOLDER' | translate }}"
            [ngClass]="{'is-invalid': userForm.submitted && subscriptionType.invalid, 'is-valid': userForm.submitted && subscriptionType.valid }"
            [(ngModel)]="senior.subscription.subscriptionType"
            (ngModelChange)="onSubscriptionTypeChange()" required>
            <ng-option *ngFor="let option of subscriptionTypeOptions"
                       [value]="option.value">{{ option.label | translate }}
            </ng-option>
          </ng-select>

          <div class="invalid-feedback" *ngIf="userForm.submitted && subscriptionType.invalid">
            <div *ngIf="subscriptionType.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          </div>
        </div>
      </div>
    </div>

    <!--      Organization-->
    <ng-container *ngIf="senior.subscription.subscriptionType === SubscriptionType.ORGANIZATION">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
          <div class="form-group">
            <label for="provider">{{ 'DOMAIN.USER.DATA.SUBSCRIPTION.ORGANIZATION.LABEL' | translate }}</label>
            <ng-select
              type="text"
              id="provider"
              name="provider"
              bindLabel="name"
              #provider="ngModel"
              placeholder="{{ 'DOMAIN.USER.DATA.SUBSCRIPTION.ORGANIZATION.PLACEHOLDER' | translate }}"
              class="provider-select"
              [items]="organizationsList$ | async"
              [ngClass]="{'is-invalid': userForm.submitted && provider.invalid, 'is-valid': userForm.submitted && provider.valid }"
              [(ngModel)]="senior.subscription.organization"
              (ngModelChange)="onProviderChange()" required>
            </ng-select>

            <div class="invalid-feedback" *ngIf="userForm.submitted && provider.invalid">
              <div *ngIf="provider.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="senior.subscription.organization as organization">
          <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
            <div class="form-group" *ngIf="organization.type === OrganizationType.OFFICIAL">
              <label for="memberCode">{{ 'VIEW.MAIN.USER.MEMBER_CODE' | translate }}</label>
              <input
                type="text"
                id="memberCode"
                name="memberCode"
                #memberCode="ngModel"
                class="form-control"
                placeholder="{{'VIEW.MAIN.USER.MEMBER_CODE' | translate }}"
                [ngClass]="{'is-invalid': userForm.submitted && memberCode.invalid, 'is-valid': userForm.submitted && memberCode.valid }"
                [(ngModel)]="senior.subscription.memberCode" required appMemberCodeValidator/>

              <div class="invalid-feedback" *ngIf="userForm.submitted && memberCode.invalid">
                <div *ngIf="memberCode.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
                <div *ngIf="memberCode.errors['memberCode']">{{ 'VALIDATION.INVALID_MEMBER_CODE' | translate }}</div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <div class="row">
      <div class="col-12 col-lg-9 mb-4">
        <app-thumbnail-and-files-upload
          [thumbnailUrl]="avatar?.thumbnailAsset?.url"
          [thumbnailPlaceholder]="'VIEW.MAIN.COACHES.PICTURE' | translate"
          [thumbnailMode]="true"
          (startUpload)="handleStartUpload($event)"
          (imageId)="handleImageId($event)"
        ></app-thumbnail-and-files-upload>
      </div>
    </div>
  </form>
</section>
