import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Constant } from 'app/common/Constant';
import { State } from 'app/common/State';
import { CoachCertificateDTO } from 'app/data/dto/coach/CertificateDTO';
import { CoachHobbyDTO } from 'app/data/dto/coach/CoachHobbyDTO';
import { CoachProfileCreateAdminRequestDTO } from 'app/data/dto/coach/CoachProfileCreateAdminRequestDTO';
import { CoachEducationDTO } from 'app/data/dto/coach/EducationDTO';
import { UserCoachProfileRequestDTO } from 'app/data/dto/coach/UserCoachProfileRequestDTO';
import { UserGender } from 'app/data/enum/account/UserGender';
import { CoachType } from 'app/data/enum/coach/CoachType';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { CoachModel } from 'app/model/CoachModel';
import { StateUtil } from 'app/util/StateUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import _ from 'lodash';
import { Subject, throwError } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { PortalUtil } from 'app/util/PortalUtil';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';
import { RegistrationType } from 'app/data/enum/user/RegistrationType';

@Component({
  selector: 'app-coaches-create',
  templateUrl: './CoachesCreateComponent.html',
  styleUrls: [ './CoachesCreateComponent.scss' ]
})
export class CoachesCreateComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  @ViewChild('form')
  public form: NgForm;

  public destroy$: Subject<void> = new Subject<void>();

  public coach: CoachProfileCreateAdminRequestDTO = new CoachProfileCreateAdminRequestDTO();
  public hobbiesFormStatus: boolean = false;
  public educationFormStatus: boolean = false;
  public certificationFormStatus: boolean = false;
  public uploadStarted: boolean = false;

  public statuses: OptionItem<boolean>[] = [
    {
      value: true,
      label: 'ENUM.STATUS.ACTIVE'
    },
    {
      value: false,
      label: 'ENUM.STATUS.INACTIVE'
    }
  ];

  public types: OptionItem<CoachType>[] = this.viewUtil.createEnumSelectOptions(CoachType, 'TYPE');

  public genderList: OptionItem<UserGender>[] = this.viewUtil.createEnumSelectOptions(UserGender, 'USER_GENDER');
  public Constant = Constant;

  constructor(public viewUtil: ViewUtil,
              private applicationModel: ApplicationModel,
              private stateUtil: StateUtil,
              private coachModel: CoachModel,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef) {
  }

  public ngOnInit(): void {
    this.applicationModel.selectSideBarItemWithState(State.MAIN.ADMIN.COACHES.LIST);

    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    this.initializeCoachData();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public track(index: any, item: { item: any; id: string }): string {
    return item.id;
  }

  public handleUpdatedHobbies(items): void {
    this.coach.coachHobbies = _.cloneDeep(items);
  }

  public handleUpdatedEducations(items): void {
    this.coach.coachEducations = _.cloneDeep(items);
  }

  public handleUpdatedCertification(items): void {
    this.coach.coachCertifications = _.cloneDeep(items);
  }

  public submit(): void {
    if (this.coach.coachCertifications.every((cert) => Object.keys(cert).length === 0)) {
      this.coach.coachCertifications = [];
    }
    if (this.coach.coachEducations.every((edu) => Object.keys(edu).length === 0)) {
      this.coach.coachEducations = [];
    }
    if (this.coach.coachHobbies.every((hobby) => Object.keys(hobby).length === 0)) {
      this.coach.coachHobbies = [];
    }

    if (this.coach.user.phone === '') {
      this.coach.user.phone = null;
    }

    this.form.onSubmit(undefined);
    if (this.form.valid && this.hobbiesFormStatus && this.educationFormStatus && this.certificationFormStatus) {
      this.coachModel
        .createCoach(this.coach)
        .pipe(
          takeUntil(this.destroy$),
          tap(({ id }) => {
            this.viewUtil.showToastSuccess('COMMON.SUCCESS');
            this.stateUtil.goToState(State.MAIN.ADMIN.COACHES.DETAILS, { id });
          }),
          catchError((err) => {
            this.viewUtil.handleServerError(err);
            return throwError(() => err);
          })
        )
        .subscribe(() => {
        });
    }
  }

  public handleStartUpload(uploadStart: boolean): void {
    this.uploadStarted = uploadStart;
  }

  public handleImageId(imageId: number): void {
    this.coach.user.imageId = imageId;
  }

  public onBackClick(): void {
    this.stateUtil.goToState(State.MAIN.ADMIN.COACHES.LIST);
  }

  public onHobbiesFormStatusChange(status: boolean): void {
    this.hobbiesFormStatus = status;
  }

  public onEducationFormStatusChange(status: boolean): void {
    this.educationFormStatus = status;
  }

  public onCertificationFormStatusChange(status: boolean): void {
    this.certificationFormStatus = status;
  }

  private initializeCoachData(): void {
    this.coach.user = new UserCoachProfileRequestDTO({ registrationType: RegistrationType.EMAIL });
    const hobbiesAndPassion: CoachHobbyDTO = new CoachHobbyDTO();
    this.coach.coachHobbies = [ hobbiesAndPassion ];
    const coachEducations: CoachEducationDTO = new CoachEducationDTO();
    this.coach.coachEducations = [ coachEducations ];
    const coachCertifications: CoachCertificateDTO = new CoachCertificateDTO();
    this.coach.coachCertifications = [ coachCertifications ];
  }


}
