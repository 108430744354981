<div class="d-flex gap-4 mb-1 category-tabs">
  <div class="category-tab" [class.selected]="!category && !favorite" (click)="showAll()">
    <svg-icon src="asset/image/icon/all.svg"></svg-icon>
    <span class="text-sm">{{ 'ENUM.VIDEO_CATEGORY.ALL' | translate }}</span>
  </div>

  <div class="category-tab" [class.selected]="favorite && !category" (click)="selectFavorite()">
    <svg-icon src="asset/image/icon/heart.svg" [svgStyle]="{ 'width.px':30, 'height.px':30 }"></svg-icon>
    <span class="text-sm">{{ 'VIEW.MAIN.ON_DEMAND.FAVORITES' | translate }}</span>
  </div>

  <div *ngFor="let categoryItem of categories"
       class="category-tab"
       [class.selected]="(category === categoryItem.value) && !favorite"
       (click)="changeCategory(categoryItem.value)">
    <svg-icon [src]="categoryItem.svgSrc"></svg-icon>
    <span class="text-sm">{{ 'ENUM.VIDEO_CATEGORY.' + (categoryItem.value || 'ALL') | translate }}</span>
  </div>
</div>