<ng-template #headingTemplate>
  <h1>{{ 'VIEW.MAIN.COACHES.COACHES_EDIT' | translate }}</h1>

  <div *ngIf="updatedCoach" class="space-x-4">
    <button type="button" class="btn btn-outline-primary" (click)="onBackClick()">
      <svg-icon src="asset/image/icon/chevron-left.svg"></svg-icon>
      <span>{{ 'COMMON.BACK' | translate }}</span>
    </button>

    <button type="button" class="btn btn-primary" (click)="onDeleteClick()">
      {{ 'COMMON.DEACTIVATE' | translate }}
    </button>

    <button [disabled]="uploadStarted" type="button" class="btn btn-primary" (click)="submit()">
      <span *ngIf="uploadStarted" class="loader-white position-absolute"></span>
      <span>{{ "COMMON.SAVE" |translate }}</span>
    </button>
  </div>
</ng-template>

<section class="section">
  <ng-container *ngIf="updatedCoach">
    <div class="row no-gutters">
      <div class="col-12 col-lg-7 p-0">
        <form #form="ngForm">
          <div class="row no-gutters m-0 p-0 mb-4">
            <div
              class="col-12 col-lg-6 d-flex flex-column gap-0 d-block d-lg-none mb-4 mb-lg-0 d-flex flex-column align-items-end"
            >
              <div class="d-flex flex-column align-items-end">
                <!-- TODO nie ma w ep -->
                <!-- <p class="details-label mb-0">{{'COMMON.DATE_CREATED' | translate}}:</p> -->
                <!-- <p class="m-0">{{updatedCoach.user.createdDate | date: 'dd/MM/yyyy' }}</p> -->
              </div>
            </div>
            <div class="form-group col-12 col-lg-6 d-flex flex-column gap-lg-0 mb-4 mb-lg-0 pr-0 pr-lg-4">
              <label for="firstName"> {{ "VIEW.MAIN.COACHES.FIRST_NAME" | translate }} </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                #firstName="ngModel"
                class="form-control"
                [placeholder]="'VIEW.MAIN.COACHES.FIRST_NAME' | translate"
                [(ngModel)]="updatedCoach.user.firstName"
                required
                [appMaxLength]="Constant.TEXT_MAX_LENGTH_LG"
                [ngClass]="{'is-invalid': form.submitted && firstName.invalid, 'is-valid': form.submitted && firstName.valid }"
              />
              <div class="invalid-feedback" *ngIf="form.submitted && firstName.invalid">
                <div *ngIf="firstName.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
              </div>
            </div>

            <div class="form-group col-12 col-lg-6 d-flex flex-column gap-lg-0 mb-4 mb-lg-0">
              <label for="lastName"> {{ "VIEW.MAIN.COACHES.LAST_NAME" | translate }} </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                #lastName="ngModel"
                class="form-control"
                [placeholder]="'VIEW.MAIN.COACHES.FIRST_NAME' | translate"
                [(ngModel)]="updatedCoach.user.lastName"
                required
                [appMaxLength]="Constant.TEXT_MAX_LENGTH_LG"
                [ngClass]="{'is-invalid': form.submitted && lastName.invalid, 'is-valid': form.submitted && lastName.valid }"
              />
              <div class="invalid-feedback" *ngIf="form.submitted && lastName.invalid">
                <div *ngIf="lastName.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
              </div>
            </div>
          </div>

          <div class="row no-gutters m-0 p-0 mb-4">
            <div class="form-group col-12 col-lg-6 d-flex flex-column mb-4 mb-lg-0 pr-0 pr-lg-4">
              <label for="email"> {{ "VIEW.MAIN.COACHES.EMAIL" | translate }} </label>
              <input
                type="email"
                id="email"
                name="email"
                #email="ngModel"
                class="form-control"
                [placeholder]="'VIEW.MAIN.COACHES.EMAIL' | translate"
                [(ngModel)]="updatedCoach.user.email"
                required
                [appMaxLength]="Constant.TEXT_MAX_LENGTH_LG"
                [ngClass]="{'is-invalid': form.submitted && email.invalid, 'is-valid': form.submitted && email.valid }"
              />
              <div class="invalid-feedback" *ngIf="form.submitted && email.invalid">
                <div *ngIf="email.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
              </div>
            </div>
            <div class="form-group col-12 col-lg-6 d-flex flex-column mb-4 mb-lg-0">
              <label for="phone"> {{ "VIEW.MAIN.COACHES.PHONE" | translate }} </label>
              <input
                type="text"
                id="phone"
                name="phone"
                #phone="ngModel"
                class="form-control"
                [(ngModel)]="updatedCoach.user.phone"
                [placeholder]="'VIEW.MAIN.COACHES.PHONE' | translate"
                [ngClass]="{'is-invalid': form.submitted && phone.invalid, 'is-valid': form.submitted && phone.valid }"
                [showMaskTyped]="true"
                [prefix]="Constant.PHONE_NUMBER_RULES.prefix + ' '"
                [shownMaskExpression]="Constant.PHONE_NUMBER_RULES.expression"
                [pattern]="Constant.PHONE_NUMBER_RULES.pattern"
                [mask]="Constant.PHONE_NUMBER_RULES.mask"/>
              <div class="invalid-feedback" *ngIf="form.submitted && phone.invalid">
                <div *ngIf="phone.errors?.required">{{ 'VALIDATION.REQUIRED' | translate }}</div>
                <div *ngIf="phone.errors?.pattern">{{ 'VALIDATION.PHONE' | translate }}</div>
              </div>
            </div>
          </div>

          <div class="row no-gutters m-0 p-0 mb-4">
            <div class="form-group col-12 col-lg-6 d-flex flex-column mb-4 mb-lg-0 pr-0 pr-lg-4">
              <label for="location"> {{ "VIEW.MAIN.COACHES.LOCATION" | translate }} </label>
              <input
                type="text"
                id="location"
                name="location"
                #location="ngModel"
                class="form-control"
                [placeholder]="'VIEW.MAIN.COACHES.LOCATION' | translate"
                [(ngModel)]="updatedCoach.location"
                required
                [appMaxLength]="Constant.TEXT_MAX_LENGTH_LG"
                [ngClass]="{'is-invalid': form.submitted && location.invalid, 'is-valid': form.submitted && location.valid }"
              />
              <div class="invalid-feedback" *ngIf="form.submitted && location.invalid">
                <div *ngIf="location.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
              </div>
            </div>
            <div class="form-group col-12 col-lg-6 d-flex flex-column mb-4 mb-lg-0">
              <label for="status"> {{ "VIEW.MAIN.COACHES.STATUS" | translate }} </label>
              <ng-select
                id="status"
                name="status"
                #status="ngModel"
                [placeholder]="'One time'"
                [searchable]="false"
                [ngClass]="{'is-invalid': form.submitted && status.invalid, 'is-valid': form.submitted && status.valid }"
                [(ngModel)]="updatedCoach.user.enabled"
                required
              >
                <div class="invalid-feedback" *ngIf="form.submitted && status.invalid">
                  <div *ngIf="status.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
                </div>
                <ng-option *ngFor="let status of statuses" [value]="status.value"
                >{{ status.label | translate }}
                </ng-option>
              </ng-select>
            </div>
          </div>

          <div class="row no-gutters m-0 p-0 mb-4">
            <div class="form-group col-12 col-lg-6 d-flex flex-column mb-4 mb-lg-0 pr-0 pr-lg-4">
              <label for="email"> {{ "VIEW.MAIN.COACHES.ZOOM_USER_EMAIL" | translate }} </label>
              <input
                type="email"
                id="zoomUserEmail"
                name="zoomUserEmail"
                #zoomUserEmail="ngModel"
                class="form-control"
                [placeholder]="'VIEW.MAIN.COACHES.ZOOM_USER_EMAIL' | translate"
                [(ngModel)]="updatedCoach.zoomUserEmail"
                required
                [appMaxLength]="Constant.TEXT_MAX_LENGTH_LG"
                [pattern]="Constant.EMAIL_ADDRESS_PATTERN"
                [ngClass]="{'is-invalid': form.submitted && zoomUserEmail.invalid, 'is-valid': form.submitted && zoomUserEmail.valid }"
              />
              <div class="invalid-feedback" *ngIf="form.submitted && zoomUserEmail.invalid">
                <div *ngIf="zoomUserEmail.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
                <div *ngIf="zoomUserEmail.errors['pattern']">{{ 'VALIDATION.EMAIL' | translate }}</div>
              </div>
            </div>
          </div>

          <div class="row no-gutters m-0 p-0 mb-4">
            <div class="form-group col-12 col-lg-6 d-flex flex-column mb-4 mb-lg-0 pr-0 pr-lg-4">
              <label for="typeOfCoach"> {{ "VIEW.MAIN.COACHES.TYPE_OF_COACH" | translate }} </label>
              <ng-select
                id="typeOfCoach"
                name="typeOfCoach"
                #typeOfCoach="ngModel"
                [placeholder]="'One time'"
                [searchable]="false"
                [ngClass]="{'is-invalid': form.submitted && typeOfCoach.invalid, 'is-valid': form.submitted && typeOfCoach.valid }"
                [(ngModel)]="updatedCoach.type"
                required
              >
                <ng-option *ngFor="let type of types" [value]="type.value">{{ type.label | translate }}</ng-option>
                <div class="invalid-feedback" *ngIf="form.submitted && typeOfCoach.invalid">
                  <div *ngIf="typeOfCoach.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
                </div>
              </ng-select>
            </div>
          </div>

          <div class="form-group col d-flex flex-column mb-4 p-0">
            <label for="bio">{{ "VIEW.MAIN.COACHES.BIO" | translate }}</label>
            <textarea
              id="bio"
              name="bio"
              #bio="ngModel"
              class="form-control"
              placeholder="Text"
              rows="5"
              maxlength="255"
              [ngClass]="{'is-invalid': form.submitted && bio.invalid, 'is-valid': form.submitted && bio.valid }"
              [(ngModel)]="updatedCoach.bio"
            ></textarea>

            <div class="d-flex justify-content-end">{{ bio.value ? bio.value.length : 0 }}/255</div>
          </div>

          <div class="form-group col d-flex flex-column mb-4 p-0">
            <app-dynamic-input
              [label]="'VIEW.MAIN.COACHES.HOBBIES_PASSIONS' | translate"
              [items]="updatedCoach.coachHobbies"
              keyName="hobby"
              [addButtonText]="'COMMON.ADD' | translate"
              [placeholder]="'VIEW.MAIN.COACHES.HOBBIES_PASSIONS' | translate"
              (updatedItems)="handleUpdatedHobbies($event)"
              [appMaxLength]="Constant.TEXT_MAX_LENGTH_255"
              [formSubmitted]="form.submitted"
              (formStatus)="onHobbiesFormStatusChange($event)"
            ></app-dynamic-input>
          </div>

          <div class="form-group col d-flex flex-column mb-4 p-0">
            <app-dynamic-input
              [label]="'VIEW.MAIN.COACHES.EDUCATION' | translate"
              [items]="updatedCoach.coachEducations"
              keyName="education"
              [addButtonText]="'COMMON.ADD' | translate"
              [placeholder]="'VIEW.MAIN.COACHES.EDUCATION' | translate"
              (updatedItems)="handleUpdatedEducations($event)"
              [appMaxLength]="Constant.TEXT_MAX_LENGTH_255"
              [formSubmitted]="form.submitted"
              (formStatus)="onEducationFormStatusChange($event)"
            ></app-dynamic-input>
          </div>

          <div class="form-group col d-flex flex-column mb-4 p-0">
            <app-dynamic-input
              [label]="'VIEW.MAIN.COACHES.CERTIFICATIONS' | translate"
              [items]="updatedCoach.coachCertifications"
              keyName="certification"
              [addButtonText]="'COMMON.ADD' | translate"
              [placeholder]="'VIEW.MAIN.COACHES.CERTIFICATIONS' | translate"
              (updatedItems)="handleUpdatedCertification($event)"
              [appMaxLength]="Constant.TEXT_MAX_LENGTH_255"
              [formSubmitted]="form.submitted"
              (formStatus)="onCertificationFormStatusChange($event)"
            ></app-dynamic-input>
          </div>
          <app-thumbnail-and-files-upload
            [thumbnailUrl]="coach?.user?.avatar?.defaultAsset?.url"
            [thumbnailPlaceholder]="'VIEW.MAIN.COACHES.PICTURE' | translate"
            [thumbnailMode]="true"
            (startUpload)="handleStartUpload($event)"
            (imageId)="handleImageId($event)"
          ></app-thumbnail-and-files-upload>
        </form>
      </div>
      <div class="col-5 p-0 d-none d-lg-block">
        <div class="d-flex justify-content-end mr-4">
          <p class="details-label">
            <!-- TODO nie ma w ep -->
            <!-- {{'COMMON.DATE_CREATED' | translate}}: {{coach.user.createdDate | date: 'dd/MM/yyyy' }} -->
          </p>
        </div>
      </div>
    </div>
  </ng-container>
</section>
