import { BreakpointObserver } from '@angular/cdk/layout';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { State } from 'app/common/State';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import {
  WatchPartyAvailableListComponent
} from 'app/component/view/main/onDemand/senior/view/watchParty/component/availableList/WatchPartyAvailableListComponent';
import {
  WatchPartyPlannedListComponent
} from 'app/component/view/main/onDemand/senior/view/watchParty/component/plannedList/WatchPartyPlannedListComponent';
import { TutorialType } from 'app/data/dto/user/TutorialType';
import { UserDetailsDTO } from 'app/data/dto/user/UserDetailsDTO';
import { UserDTO } from 'app/data/dto/user/UserDTO';
import { WatchPartyWizardAnchor } from 'app/data/local/WatchPartyWizardAnchor';
import { UserModel } from 'app/model/UserModel';
import { WatchPartyModel } from 'app/model/WatchPartyModel';
import { PortalUtil } from 'app/util/PortalUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { NgxPopperjsPlacements } from 'ngx-popperjs';
import { TourWizardService, TourWizardStep } from 'ngx-tour-wizard';
import { first, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-watch-party-list',
  templateUrl: './WatchPartyListComponent.html',
  styleUrls: ['./WatchPartyListComponent.scss']
})
export class WatchPartyListComponent implements OnInit, OnDestroy {
  public readonly WatchPartyWizardAnchor: typeof WatchPartyWizardAnchor = WatchPartyWizardAnchor;
  @ViewChild(WatchPartyPlannedListComponent)
  private readonly watchPartyPlannedListComponent: WatchPartyPlannedListComponent;

  @ViewChild(WatchPartyAvailableListComponent)
  private readonly watchPartyAvailableListComponent: WatchPartyAvailableListComponent;

  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  private readonly canDisplayOnboardingTour: boolean = false;

  private onboardingTourStepList: TourWizardStep[] = [];

  public State: typeof State = State;

  public currentUser: UserDTO;

  constructor(private readonly portalUtil: PortalUtil,
              public readonly watchPartyModel: WatchPartyModel,
              public userModel: UserModel,
              private readonly viewContainerRef: ViewContainerRef,
              private readonly tourWizardService: TourWizardService,
              private readonly viewUtil: ViewUtil,
              private readonly breakpointObserver: BreakpointObserver) {
    this.canDisplayOnboardingTour = this.breakpointObserver.isMatched('(min-width: 768px)');
    this.currentUser = this.userModel.currentUser;
    this.onboardingTourStepList = [
      {
        anchorId: WatchPartyWizardAnchor.ALL,
        title: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_WATCH_PARTY_ALL.TITLE'),
        content: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_WATCH_PARTY_ALL.DESCRIPTION'),
        popperSettings: {
          placement: NgxPopperjsPlacements.BOTTOM,
          positionFixed: true
        }
      },
      {
        anchorId: WatchPartyWizardAnchor.CREATE_WATCH_PARTY,
        title: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_WATCH_PARTY_CREATE.TITLE'),
        content: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_WATCH_PARTY_CREATE.DESCRIPTION'),
        popperSettings: {
          placement: NgxPopperjsPlacements.BOTTOM,
          positionFixed: true
        }
      }
    ];
  }

  public ngOnInit(): void {
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    setTimeout(() => {
      this.startOnboardingTour()
    })
  }

  public ngOnDestroy(): void {
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public onAvailableListRefresh(): void {
    this.watchPartyPlannedListComponent.loadData();
  }

  public onPlannedListRefresh(): void {
    this.watchPartyAvailableListComponent.loadData();
  }

  private startOnboardingTour(): void {
    if (this.currentUser?.completedTutorials?.includes(TutorialType.WATCH_PARTY) || !this.canDisplayOnboardingTour) {
      return;
    }

    setTimeout(() => {
      this.tourWizardService.initialize(this.onboardingTourStepList);
      this.tourWizardService.start();

      // add progress per step
      this.onboardingTourStepList.forEach((step: TourWizardStep, index: number) => {
        const tourStepElement: HTMLElement = document.querySelector('[tourWizardAnchor="' + step.anchorId + '"] + tour-wizard-popper-component');
        tourStepElement.classList.add('tour-wizard-progress');
        tourStepElement.style.setProperty('--current-step', (index + 1).toString());
        tourStepElement.style.setProperty('--all-steps', (this.onboardingTourStepList.length).toString());
      });

      this.tourWizardService.end$.pipe(
        first(),
        switchMap(() => this.userModel.setCurrentUserTutorialCompletion(TutorialType.WATCH_PARTY))
      ).subscribe((userDetails: UserDetailsDTO) => {
        this.currentUser.completedTutorials = userDetails.completedTutorials;
      });
    });
  }
}
