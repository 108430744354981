export enum TutorialType {
  DASHBOARD = 'DASHBOARD',
  LIVE_CLASSES = 'LIVE_CLASSES',
  ON_DEMAND = 'ON_DEMAND',
  MY_SCHEDULE = 'MY_SCHEDULE',
  MY_ACTIVITY = 'MY_ACTIVITY',
  HELP = 'HELP',
  ACCOUNT = 'ACCOUNT',
  FRIENDS = 'FRIENDS',
  WATCH_PARTY = 'WATCH_PARTY'
}